<template>
  <div v-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-television</v-icon>
      <v-toolbar-title>
        {{ $t('common.workspace', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Name"
            :label="$t('sysUser.name', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-autocomplete
            v-model="editedItem.Languages"
            :items="languages"
            outlined
            chips
            small-chips
            :label="$t('workspace.languages', locale)"
            multiple
            itemText="NormalName"
            itemValue="ID"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-autocomplete
            v-model="editedItem.Timezone"
            :items="timezones"
            dense
            filled
            :label="$t('workspace.timezone', locale)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <color-picker-primary-secondary
            style="width: 100px;"
            :primaryColor="editedItem.PrimaryColor"
            :secondaryColor="editedItem.SecondaryColor"
            :onChangeColor="handleChangeColors"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <select-sys-users
            area="dviewcontent"
            :workspaceID="editedItem.ID"
            :onChangeUser="handleChangeUser"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-expansion-panels focusable>
            <custom-content-type
              v-if="editedID"
              :workspaceID="editedID"
              :onChange="handleChangeCustomContentType"
              :locale="locale"
            />
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <content-type
            style="background-color: rgba(0, 0, 0, 0.06);" 
            v-if="editedID"
            :workspaceID="editedID"
            :onChangeContentTypes="handleChangeContentTypes"
          />
        </v-col>
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
</template>
<script>
  const defaultItem = {
    Name: '',
    Users: [],
    PrimaryColor: null,
    SecondaryColor: null,
    ContentTypes: [],
    Languages: ['es', 'en'],
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import utils from '@/services/utils'
  import constants from '@/constants'
  import SelectSysUsers from '@/components/SelectSysUsers'
  import ActionButtons from '@/components/ActionButtons'
  import ColorPickerPrimarySecondary from '@/components/ColorPickerPrimarySecondary'
  import ContentType from './contentType/Index'
  import CustomContentType from './customContentType/Index'
  export default{
    name: 'WorkspaceForm',
    components: {
      ActionButtons,
      SelectSysUsers,
      ColorPickerPrimarySecondary,
      ContentType,
      CustomContentType,
    },
    props: {
      editedID: {
        type: String,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onDelete: {
        type: Function,
        required: true
      },
      onSave: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
    },
    data () {
      return {
        loading: false,
        editedItem: null
      }
    },
    computed: {
      ...mapState('app', ['locale']),
      timezones () {
        return constants.timezones
      },
      languages () {
        return utils.getAllLanguages()
      },
    },
    watch: {
      showForm (v) {
        if (v) {
          if(this.editedID) this.handleGetData()
          else this.editedItem = defaultItem
        }
      },
      editedID (v) {
        if (this.showForm) {
          if (v) this.handleGetData()
          else this.editedItem = defaultItem

        }
      },
    },
    methods: {
      handleGetData () {
        this.loading = true
        api.getItem ('dviewcontent', 'v1/system/workspaces/', this.editedID)
        .then(response => {   
          if (response && response.Languages) response.Languages = JSON.parse(response.Languages)
          this.editedItem = response
          this.loading = false
        })
 
      },
      handleChangeColors (primaryColor, secondaryColor) {
        this.editedItem.PrimaryColor = primaryColor ? primaryColor : null
        this.editedItem.SecondaryColor = secondaryColor ? secondaryColor : null
      },
      handleChangeUser (v) {
        this.editedItem.Users = v
      },
      handleChangeCustomContentType (v) {
        console.log(v)
        this.editedItem.CustomContentTypes = v
      },
      handleChangeContentTypes (v) {
        this.editedItem.ContentTypes = v
      },
      handleSave () {
        console.log(this.editedItem)
        this.onSave(this.editedItem)
      },
      handleDelete () {
        this.onDelete(this.editedItem.ID)
      }
    }
  }
</script>

